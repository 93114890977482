import { Trans } from '@lingui/macro'
import React from 'react'

import styles from '../SideDrawer.module.css'
import MainSliderLink from './MainSlideLink'

const MainSliderItem = () => (
  <ul className={styles.sideDrawerMainItem}>
    <li>
      <MainSliderLink href='/lifestages/pfp' imageAlt='pfp' imageSrc='https://d2wp562hbbs2nm.cloudfront.net/web/images/navigation_ttc.jpg'>
        <div className={styles.sideDrawerMainItemMainTitle}>
          <Trans>Planning For</Trans>
        </div>
        <div className={styles.sideDrawerMainItemSubTitle}>
          <Trans>Pregnancy</Trans>
        </div>
      </MainSliderLink>
    </li>
    <li>
      <MainSliderLink
        href='/lifestages/pregnant'
        imageAlt='pregnant'
        imageSrc='https://d2wp562hbbs2nm.cloudfront.net/web/images/navigation_pregnancy.jpg'
      >
        <div className={styles.sideDrawerMainItemMainTitle}>
          <Trans>I am</Trans>
        </div>
        <div className={styles.sideDrawerMainItemSubTitle}>
          <Trans>Pregnant</Trans>
        </div>
      </MainSliderLink>
    </li>
    <li>
      <MainSliderLink
        href='/lifestages/mother'
        imageAlt='mother'
        imageSrc='https://d2wp562hbbs2nm.cloudfront.net/web/images/navigation_parenting.jpg'
      >
        <div className={styles.sideDrawerMainItemMainTitle}>
          <Trans>I have</Trans>
        </div>
        <div className={styles.sideDrawerMainItemSubTitle}>
          <Trans>a Child</Trans>
        </div>
      </MainSliderLink>
    </li>
  </ul>
)

export default MainSliderItem
